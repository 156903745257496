import React, { useState } from "react";
import api from '../../../../services/api';

import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableOTAImport.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import ImportWarns from "./components/ImportWarns/ImportWarns";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  //exportBills: any;
  //printBills: any;
  actualDate: any;
  parameters?: any;
  refreshData?: any;
}

const TableOTAImport: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading,
  //exportBills, printBills,
  actualDate, parameters,
  refreshData
}: propTable) => {

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);

  const [importWarnsModalShow, setImportWarnsModalShow] = useState<any>(false);
  const [pickupListModalShow, setPickupListModalShow] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const showRejectedRows = (id: any) => {
    setSelectedSingleRow(id);
    setImportWarnsModalShow(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showPendentesRows = (id: any) => {
    setSelectedSingleRow(id);
    setPickupListModalShow(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showPickupListModal = (id: any) => {
    setSelectedSingleRow(id);
    setPickupListModalShow(true);
  }

  const {
    control,
    formState: { errors },
  } = useForm();

  function addRejeitadosButton(cell: any, row: any) {
    if (row.qtyRejected > 0) {
      return (
        <>
          <button className="btn btn-bills" onClick={() => showRejectedRows(row.id)}>
            {cell}
          </button>
        </>
      );
    } else {
      return (
        <>
          {cell}
        </>
      );
    }
  }

  function addPendentesButton(cell: any, row: any) {
    // if (row.qtdePendentes > 0) {
    //   return (
    //     <>
    //       <button className="btn btn-bills" onClick={() => showPendentesRows(row.id)}>
    //         {cell}
    //       </button>
    //     </>
    //   );
    // } else {
      return (
        <>
          {cell}
        </>
      );
    //}
  }

  const fileHandler = (e: any) => {
    setModalContent("loading");
    setModalSuccess(true);

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        //setFile(reader.result)
        const file = reader.result;

        const importSpreadsheet = async () => {
          const token = localStorage.getItem('GroupId') || '';

          const config = {
            headers: { 'Authorization': `Bearer ${token}` },
          };
          try {

            const res = await api.post(`/BillsReceivable/ImportOTABookings`, {
              "file": file
            }, config);
            /* console.log(data.data); */
            if (res.status !== 400) {
              setResponseText(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent("success");
              console.log(res)
            } else {
              setResponseText(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent("error");
            }
            //setLoading(false);
            //closeModal();
            //setModalSuccess(true);
          } catch (error: any) {
            //setLoading(false);
            setLog(1);
            setModalContent("error");
            setResponseText("Ocorreu um erro, tente novamente mais tarde");

          }
        }
        importSpreadsheet();
      }
    }
    reader.readAsDataURL(e.target.files[0]);
    //setFileName(e.target.files[0].name);
  }

  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  const columns = [
    {
      dataField: "importDate",
      text: "Data",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "importTime", text: "Hora", sort: true },
    { dataField: "userName", text: "Usuario", sort: true },
    { dataField: "qtyRecords", text: "Qtde Registros" },
    { dataField: "qtyAccepted", text: "Qtde Aceitos" },
    {
      dataField: "qtyRejected",
      text: "Qtde Rejeitados",
      formatter: addRejeitadosButton,
    },
    { dataField: "qtyPending", text: "Qtde Pendentes", formatter: addPendentesButton },
    /* { dataField: "id", text: "Ação", formatter: addActionButton }, */
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "importacoes", text: "Importações" }];

  const notFoundProducts = [{ contas: "Nenhuma importação encontrada" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando importações", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusReserva === "Pendente") {
      return 'border-analise';
    } else if (row.statusReserva === "Confirmada") {
      return 'border-ativo';
    } else {
      return 'border-ativo';//'border-inativo';
    }
  };

  function handleReload() {
    //window.location.reload();
    setModalSuccess(false);
    refreshData();
  }

  if (billsReceive !== null) {
    return (
      <>
        <div className="table-default table-decolar-import">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    {/* <ModalDefault
                      title="Importar XLSX"
                      name="Importar XLSX"
                      icon="yes"
                      typeIcon="file-import"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    > */}

                    <Controller
                      control={control}
                      name="arquivo"
                      render={({ field }: any) => (
                        <Form.Control type="file"
                          {...field}
                          label="arquivo"
                          aria-invalid={errors?.arquivo ? "true" : ""}
                          fullWidth
                          variant="standard"
                          margin="normal"
                          id="arquivo"
                          accept=".xlsx"
                          onChange={fileHandler}
                          autoComplete='off'
                          required />
                      )}
                    />
                    <label className="btn btn-bills" htmlFor="arquivo">
                      <FontAwesomeIcon
                        icon={["fal", "file-import"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />Importar XLSX
                    </label>

                    {/* </ModalDefault> */}
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        {/* Modal visualizar reistros rejetados */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={importWarnsModalShow}
          onHide={() => setImportWarnsModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <ImportWarns
            importId={selectedSingleRow}
            setModalShow={setImportWarnsModalShow}
          />
        </Modal>
        {/* END - Modal visualizar reistros rejetados */}

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => { if (modalContent !== 'loading') setModalSuccess(false); }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "file-import"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Importar XLSX
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableOTAImport;
