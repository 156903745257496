import { faH4 } from "@fortawesome/pro-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";

interface propsModalProgress {
    loadingCognitivoProgress: boolean;
    cognitivoProgressObj: any;
    setCognitivoProgressObj: any;
    modalProgressOpen: boolean;
    setModalProgressOpen: any;
    setLoadingCognitivoProgress?:any
}

const ModalProgress: React.FC<propsModalProgress> = ({
    loadingCognitivoProgress,
    cognitivoProgressObj,
    modalProgressOpen,
    setModalProgressOpen,
    setLoadingCognitivoProgress,
    setCognitivoProgressObj
}) => {

    const processesStarted:any = JSON.parse(localStorage.getItem('processIds') || '[]');
    
    const obj = [

        {
            "processId": "458139e0-58eb-2892-fe50-649914445f71",
            "processedItems": 0,
            "totalItems": 5
        },
        {
            "processId": "458139e0-58eb-2892-fe50-649914445f72",
            "processedItems": 10,
            "totalItems": 10
        },
        {
            "processId": "458139e0-58eb-2892-fe50-649914445f73",
            "processedItems": 10,
            "totalItems": 100
        },
        {
            "processId": "458139e0-58eb-2892-fe50-649914445f74",
            "processedItems": 6,
            "totalItems": 8
        },
    ]



    function calculatePercentage(item: any) {
        const { processedItems, totalItems } = item;
        // Verifica se totalItems é maior que zero para evitar divisão por zero
        if (totalItems > 0) {
            const percentage = (processedItems / totalItems) * 100;
            return percentage.toFixed(2); // Retorna a porcentagem com duas casas decimais
        } else {
            return '0.00'; // Se totalItems for 0 ou negativo, retorna 0%
        }
    }

    const handleClose = () => {
        setLoadingCognitivoProgress(false);
        setModalProgressOpen(false);
        setCognitivoProgressObj(null)
    }
    

    return (
        <Modal
            show={modalProgressOpen}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter">
            <div className={`modal-body text-center d-flex flex-column justify-content-between`} style={{ minHeight: '600px' }}>
                <h3 className="text-primary pb-2" style={{ textAlign: 'left', borderBottom: '1px solid #6C6C6C' }}>Processos</h3>
                {cognitivoProgressObj !== null ?
                    <> <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        {processesStarted.length === 0 ?
                        <h3>Não há processos iniciados!</h3>
                        :
                        <ul>
                            {cognitivoProgressObj.filter((process:any,index:number)=> (process.processId === processesStarted[index].processId)).map((item: any, index: number) => {
                                return (
                                    <>
                                        <li style={{ textAlign: 'left', paddingBottom: '4px' }} key={index}>
                                            <span style={{ fontWeight: '500' }}>ID:</span> {item.processId}
                                            {processesStarted[index].startDate && <p><span style={{ fontWeight: '500' }}>Data:</span> {processesStarted[index].startDate.split('-').reverse().join('/')} à {processesStarted[index].endDate.split('-').reverse().join('/')}</p>}
                                        </li>
                                        <div style={{ height: '30px' }} className="progress mb-3">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${calculatePercentage(item)}%` }} aria-valuenow={item.processedItems} aria-valuemin={0} aria-valuemax={item.totalItems}>
                                                <div style={{color: 'black', position: "absolute", left: "calc(48%)" }} >{item.processedItems} / {item.totalItems}</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                        </ul>
}
                    </div>
                    </>
                    : cognitivoProgressObj === null && loadingCognitivoProgress === true ?
                        <div className="load-big"></div>
                    :
                    <h3>Não há processos iniciados!</h3>
                }
                <div className="modal-footer">
                    <div>
                        <Button type="button" className="btn btn-primary form-button px-4 py-2" onClick={() => handleClose()}>Fechar</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalProgress;