import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import "../../../../../../assets/sass/modal.scss";
import "./SupplierPaymentsByDay.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../../services/api';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

export interface propModal {
  date: any,
  supplierId: any,
  supplierName: any,
  setModalShow: any
  // show: any,
  // setResponseText: any,
  // setModalContent: any,
  // setLog: any,
};

const SupplierPaymentsByDay: React.FC<propModal> = ({
  date, supplierId, supplierName, setModalShow //show, setResponseText, setModalContent, setLog
}: propModal, props: any) => {
  const [messageList, setMessageList] = useState<any>(null);

  //const [messageToSend, setMessageToSend] = useState<any>('');

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();


  const getSessionMessages = async () => {
    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

        const { data } = await api.post('/Extract/GetSupplierPaymentsByDay', {
          paymentDate: date,
          supplierId: supplierId
        }, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
            var response = data.data;

            setMessageList(response);
        }   
    }catch(error: any) {
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
            //window.location.href = window.location.origin + '/session-closure';              
        }
        //;
    }
  }

  function convertDate(cell: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    if (!cell) return '';
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  const columns = [
    { dataField: "serviceDate", text: "Data do Tour", sort: true, formatter: convertDate },
    { dataField: "account", text: "Conta/Caixa", sort: true },
    { dataField: "description", text: "Descrição", sort: true },
    { dataField: "amount", text: "Valor", sort: true, formatter: convertValue },
  ];

  const rowClasses = (row: any, rowIndex: any) => {
      return 'border-ativo';//'border-inativo';
  };

  useEffect(() => {
    
    if(date) getSessionMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  if(date !== null && messageList !== null) {
    return (
      <>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <h3>Pagamentos - {supplierName} - {convertDate(date)}</h3>
          </div>
        </Modal.Header>
        <hr style={{ margin: "30px 0" }} />
        <div className='d-flex flex-column justify-content-between h-100'>

            <div className="table-add-default groupfile-car-table">
              <div className="table-container">   
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={messageList}
                  columns={columns}
                  rowClasses={rowClasses}
                  striped={true}
                />
              </div>
            </div> 
            <div className='mt-4 d-flex justify-content-end gap-2'>
              <Button type="button" className="form-button" onClick={() => setModalShow(false)}>Voltar</Button>
            </div>
        </div>
      </>
    );
  } else {
    return (<></>);
  }
  
}

export default SupplierPaymentsByDay;
