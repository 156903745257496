import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './ReportNfAudit.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterReportNfAudit from './components/FilterReportNfAudit/FilterReportNfAudit'; 
import TableReportNfAudit from './components/TableReportNfAudit/TableReportNfAudit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row } from 'react-bootstrap';

function ReportNfAudit() {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;


    const [actualDate, setActualDate] = useState<any>([todayStr]);

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [totalReceive, setTotalReceive] = useState<any>(0);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function listBills() {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };

                const { data } = await api.post('/SessionClosure/GetAuditReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    setTotalReceive(data.data.totalReceive);
                }
            } catch (error: any) {
                //if (error.response.status === 401) {
                //    window.location.href = window.location.origin + '/';
                //}
            }
        }
        listBills();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(
        date: any
    ) {

        setActualDate(date);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/SessionClosure/GetAuditReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setError(true)
            //if (error?.response?.status === 401) {
            //    window.location.href = window.location.origin + '/';
            //}
        }
    };

    async function exportBills() {
        let allowRequest: boolean = true;

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        if (allowRequest === true) {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const exp = await api.post('/SessionClosure/ExportAuditReport',
                    {
                        "page": 1,
                        "rowsPerPage": 0, // pagina unica
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config

                );
                if (exp.status !== 400) {
                    
                    const dados = exp.data.data;

                    const objectUrl = `data:${dados.fileType};base64,${dados.file}`;                

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        dados.fileName,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {
                setError(true)
                //if (error?.response?.status === 401) {
                //    window.location.href = window.location.origin + '/';
                //}
            }
        }
    };

    // async function printBills() {

    //     let beginDate = []
    //     let endDate = []

    //     if (actualDate.length === 1) {
    //         beginDate = actualDate[0].split('/')
    //     } else if (actualDate.length === 2) {
    //         beginDate = actualDate[0].split('/')
    //         endDate = actualDate[1].split('/')
    //     }

    //     let today: any = new Date();

    //     try {
    //         const print = await api.post('/BillsReceivable/PrintReport',
    //             {
    //                 "page": 1,
    //                 "rowsPerPage": 0, // pagina unica
    //                 "item": actualLocalizador ? actualLocalizador : '',
    //                 "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
    //                 "tokenNsu": actualToken ? actualToken : '',
    //                 "agenteRef": actualRefAgent ? actualRefAgent : '',
    //                 "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
    //                 "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
    //                 "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
    //                 "adquirente": actualAdquirente ? actualAdquirente : null,
    //                 "fornecedor": actualSupplier ? actualSupplier : null,
    //                 "agenteIndividual": -1,
    //                 "agente": actualAgent && actualAgent !== '0' ? actualAgent : null,
    //                 "cliente": actualClient ? actualClient : '',
    //                 "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
    //                 "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    //                 "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Accept": "*/*"
    //                     //"Access-Control-Allow-Origin": "*"

    //                 },
    //                 responseType: 'blob'
    //             },

    //         );
    //         if (print.status !== 400) {
    //             const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
    //             const objectUrl = window.URL.createObjectURL(blob);

    //             const link = document.createElement('a');
    //             link.href = objectUrl;
    //             link.setAttribute(
    //                 'download',
    //                 'contas_receber.pdf',
    //             );

    //             // Append to html link element page
    //             document.body.appendChild(link);

    //             // Start download
    //             link.click();

    //             // // Clean up and remove the link
    //             link?.parentNode?.removeChild(link);


    //         }
    //     } catch (error: any) {
    //         setError(true)
    //         if (error?.response?.status === 401) {
    //             window.location.href = window.location.origin + '/';
    //         }
    //     }
    // };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard report-nf-audit">
                    <Breadcrumb title={'Financeiro / Relatórios / Relatorio Auditoria Notas Fiscais'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatorio Auditoria Notas Fiscais</h2>
                    </div>
                    <FilterReportNfAudit
                        buscar={buscar}
                        defaultDate={todayStr}
                    />
                    <Row className="cards">
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Valor Total
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalReceive.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "receipt"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        {/* <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Pagamentos
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalPagamentos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "hand-holding-usd"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Margem
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalMargem.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "dollar-sign"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Notas Emitidas
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalNotas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Prejuizo Apurado
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalPrejuizo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Verificação
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {verificacao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div> */}
                    </Row>
                    <TableReportNfAudit
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        //printBills={printBills}
                        loading={loading}
                        //totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default ReportNfAudit;