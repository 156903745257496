import { Button, Modal } from "react-bootstrap";

interface propsModalStartModel {
    loadingStartModel?: boolean;
    startModelObj?: any;
    ModalStartModelOpen: boolean;
    setModalStartModelOpen: any;
    message?:string;
    setMessage?:any
}

const ModalStartModel: React.FC<propsModalStartModel> = ({
    loadingStartModel,
    startModelObj,
    ModalStartModelOpen,
    setModalStartModelOpen,
    message,
    setMessage
}) => {
    const obj =[
       
            {
                "processId": "458139e0-58eb-2892-fe50-649914445f71",
                "processedItems": 0,
                "totalItems": 5
            },
            {
                "processId": "458139e0-58eb-2892-fe50-649914445f72",
                "processedItems": 10,
                "totalItems": 10
            },
            {
                "processId": "458139e0-58eb-2892-fe50-649914445f73",
                "processedItems": 10,
                "totalItems": 100
            },
            {
                "processId": "458139e0-58eb-2892-fe50-649914445f74",
                "processedItems": 6,
                "totalItems": 8
            }, 
    ]


    const handleClose = () => {
        setMessage('');        
        setModalStartModelOpen(false)
    }
    
console.log(loadingStartModel ,message)
    return (
        <Modal
            show={ModalStartModelOpen}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter">
             <div className={`modal-body text-center d-flex flex-column justify-content-between`} style={{ minHeight: '600px' }}>
                <h3 className="text-primary pb-2" style={{ textAlign: 'left', borderBottom: '1px solid #6C6C6C' }}>Iniciar modelo</h3>
                {message?.length !== 0 ?
                    <> <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        <h3>{message}</h3>
                    </div>
                    </>
                    : message?.length === 0 && loadingStartModel === true ?
                        <div className="load-big"></div>
                    :
                        <>
                        </>
                }
                <div className="modal-footer">
                    <div>
                        <Button type="button" className="btn btn-primary form-button px-4 py-2" onClick={() => handleClose()}>Fechar</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalStartModel;