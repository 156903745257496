/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useEffect, useState } from "react";
import { Container, Row, Col, Table, InputGroup } from "react-bootstrap";

import "../../../../../../assets/sass/modal.scss";
import "./PickupList.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../../services/api';
import { ErrorMessage } from "@hookform/error-message";
// import GetPickupLocation from "../../../../../../components/C2Points/GetPickupLocation";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Pagination from "../../../../../../components/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import GetPickupLocationList from "../../../../../../components/C2Points/GetPickupLocationList";

export interface propModal {
  //importId: any,
  //setModalShow: any,
  show: any,
  setResponseText: any,
  setModalContent: any,
  setLog: any,
  updatePickupPendCounter: any,
};


const PickupList: React.FC<propModal> = ({
  //setModalShow ,
  show, setResponseText, setModalContent, setLog, updatePickupPendCounter
}: propModal, props: any) => {
  const [pickupList, setPickupList] = useState<any>(null);
  const [locationList, setLocationList] = useState<any>([]);
  const [info, setInfo] = useState<any>([]);

  //const [messageToSend, setMessageToSend] = useState<any>('');

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(5);
  /* END - Pagination */

  const [loading, setLoading] = useState<any>(false);

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando pickups", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;


  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const formatDate = (data: any) => {
    if (!data) return '';

    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  const getSessionMessages = async (localizador: string = getValues().localizador, agent: string = getValues().agent, resetPagination: boolean = false) => {
    setLoading(true);
    const token = localStorage.getItem('GroupId') || '';

    if (resetPagination) {
      setPageCount(1);
    }

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

      const { data } = await api.post(`/Decolar/GetPickupReportNewAsync`, {
        "localizador": localizador,
        "agent": agent,
        "page": resetPagination ? 1 : pageCount,
        "rowsPerPage": rowsPerPage
      }, config);

      /* const { data } = await api.post(`/Decolar/GetPickupReportAsync`, {
        "page": pageCount,
        "rowsPerPage": rowsPerPage
      }, config); */

      /* console.log(data.data); */
      if (data.status !== 400) {
        const response = data.data;
        const items = response.rows;

        for (let i = 0; i < items.length; i++) {
          if (items[i].embarkingType === 1) {
            items[i].pickup = '';
          } else {
            items[i].pickup = items[i].meetingPoint;
          }
        }

        setTotalRows(response.rowsCount);
        setPickupList(items);

      }
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
      if (error?.response?.status === 400) {
        //window.location.href = window.location.origin + '/session-closure';              
      }
      //;
    }

  }
  
  useEffect(() => {

    getSessionMessages();

    for (let i: number = 0; i < 5; i++) {
      setValue(`pickup${i}`, '');
      clearErrors(`pickup${i}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  function salvar(index: any) {
    const data = pickupList[index];

    clearErrors(`pickup${index}`);

    async function settle() {
      show(true);
      setModalContent("loading");

      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {

        const res = await api.post('/Decolar/UpdatePickupDecolarAsync',
          {
            "itemId": data.id,
            "pickup": getValues()[`pickup${index}`].value,//data.pickup
            "phone": getValues()[`phone${index}`]
            //"uid": uId,
            //"usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          setResponseText(res.data.data.texto);
          setLog(res.data.data.log);

          if (res.data.data.log === 0) {
            setModalContent('successNoRefresh');
          }
          else if (res.data.data.log === 1) {
            setModalContent('error');
          }
          getSessionMessages();
        }
      } catch (error: any) {

        if (error?.response?.status === 401) {
        }
      }
    }

    if (getValues()[`pickup${index}`]/* data.pickup !== '' */) {
      settle();
      updatePickupPendCounter();
    } else {
      setModalContent("warning");
      setResponseText("Pickup não informado");
      setLog(2);
      show(true);
      setError(`pickup${index}`, { type: 'custom', message: `Obrigatório` });
    }
  }


  /* const onSubmit = (data: any) => {
    getSessionMessages(data.localizador, data.agent)
  } */
  const handleClickSubmit = () => {
    let data: any = getValues();
    getSessionMessages(data.localizador, data.agent, true)
  }

  
  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
      pickupList?.map((item:any) => {
        async function productsListCod() {
          try {
            const { data } = await api.get(`/Decolar/GetPickupLocationsAsync/${item.pickupsList}`, config);
            if (data.status !== 400) {
              setLocationList((prev:any) => [...prev, data.data[0]])
            }
          } catch (error: any) {        
          }
        }
        productsListCod();
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, pickupList);

  const returnPickupName = (pickup:any) => {
  const pickupObj:any = locationList?.find((item:any) => item?.idPickup == pickup?.pickupsList);
  return pickupObj?.namePickupLocation || 'Não Informado';

}
  

  if (pickupList !== null/*  && loading === false */) {
    return (
      <>
        <Modal.Body className="p-0 decolar-products">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column pickuplist-table">
              <div className="table-container">

                <div>
                  <Row as={Col} md="12">
                    <Form /* onSubmit={handleSubmit(onSubmit)} */>
                      <Col md="12" className="d-flex justify-content-md-start">
                        <Row className="w-100 m-0">
                          <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Localizador:</Form.Label>
                            <Controller
                              control={control}
                              name="localizador"
                              defaultValue={''}
                              render={({ field }: any) => (
                                <>
                                  <Form.Control
                                    {...field}
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                  />
                                </>
                              )}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Agente:</Form.Label>
                            <Controller
                              control={control}
                              name="agent"
                              defaultValue={''}
                              render={({ field }: any) => (
                                <>
                                  <Form.Control
                                    {...field}
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                  />
                                </>
                              )}
                            />
                          </Form.Group>
                          <Col md="6" className="d-flex justify-content-md-end align-items-end">
                            <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" style={{ height: '36px', padding: '0px' }} onClick={() => { setValue('localizador', ''); setValue('agent', ''); getSessionMessages('', '', true); }}>Limpar</Button>
                            <Button type="button" className="btn btn-primary btn-default text-uppercase" style={{ height: '36px', padding: '0px' }} onClick={() => { handleClickSubmit() }}>Buscar</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Form>
                  </Row>
                </div>

                <hr className="d-block" style={{ margin: '30px 0px' }} />

                {loading === false ?
                  <>

                    <Table striped >
                      <thead>
                        <tr>
                          <th>Localizador</th>
                          <th>Item</th>
                          <th>Nome Customer</th>
                          <th>Notas Internas</th>
                          <th>Telefone</th>
                          <th>Pickup</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pickupList.length > 0 ? pickupList.map((item: any, index: any) => (
                          <tr key={index}>                            
                            <td>{item.reserva}</td>
                            <td>{item.item}</td>
                            <td>{item.nomeCustomer}</td>
                            <td>{item.internalNotes || 'N/A'}</td>
                            {/* <td>{returnPickupName(item)}</td> */}
                            <td>
                              <Controller
                                control={control}
                                name={`phone${index}`}
                                rules={{ 
                                  required: { value: true, message: 'obrigatório' },
                                  pattern: { value: /^[0-9]*$/, message: 'Somente números são permitidos' },
                                  maxLength: { value: 11, message: 'Máximo de 11 caracteres' }
                                }}
                                render={({ field }: any) => (
                                  <Form.Control type="text"
                                    {...field}
                                    aria-invalid={errors?.phone ? "true" : ""}
                                    variant="standard"
                                    autoComplete='off'
                                    margin="normal"
                                    required
                                    defaultValue={item.phone}
                                    onChange={(e: any) => { item.phone = e.target.value; field.onChange(e); }}
                                    value={item.phone}
                                  />
                                )}
                              />
                            </td>
                            <td>
                              {item.embarkingType === 1 ?
                                <>
                                  <Controller
                                    control={control}
                                    name={`pickup${index}`}
                                    rules={{ required: { value: true, message: 'obrigatorio' } }}
                                    render={({ field }: any) => (
                                      <GetPickupLocationList
                                       propsErrors={errors}
                                        propsField={field}
                                         propsLabel={"Pickup"}
                                          pickupId={item.pickupsList}
                                           setPickup={(value: any) => item.pickup = value}
                                            pickup={item.pickup}
                                             info={info}
                                              setInfo={setInfo}  />
                                    )}
                                  />
                                </>
                                :
                                <>
                                  <Controller
                                    control={control}
                                    name={`pickup${index}`}
                                    rules={{ required: { value: true, message: 'obrigatorio' } }}
                                    render={({ field }: any) => (
                                      <Form.Control type="text"
                                        {...field}
                                        aria-invalid={errors?.pickup ? "true" : ""}

                                        variant="standard"
                                        autoComplete='off'
                                        margin="normal"
                                        required
                                        defaultValue={item.pickup}
                                        onChange={(e: any) => { item.pickup = e.target.value; field.onChange(e); }}
                                        value={item.pickup}
                                      />
                                    )}
                                  />
                                </>
                              }
                            </td>
                            <td>
                              <span className='btn' style={{ height: "40px" }} onClick={(e: any) => salvar(index)}>
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={["fal", "check"]}
                                  style={{ color: "#707070" }}
                                  title="Salvar"
                                />
                              </span>
                            </td>
                          </tr>
                        ))
                          :
                          <tr><td colSpan={5} className="mb-2 text-center"><span>Nenhum pickup pendente!</span></td></tr>}
                      </tbody>
                    </Table>
                    <Pagination
                      totalRows={totalRows}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      selectNumberRows="no"
                    />
                  </>
                  :
                  <>
                    <div className="text-center my-5">
                      <div className="load-big primary">

                      </div>
                    </div>
                  </>
                }
              </div>

              {/* <div className="mt-auto">
                <Form
                  className="form-add-contact"
                  noValidate
                >
                  <Row className="d-flex justify-content-center">
                    <Form.Group as={Col} md="4">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => setModalShow(false)}
                      >
                        <FontAwesomeIcon
                          icon={["fal", "check"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        /> Confirmar
                      </button>
                    </Form.Group>
                  </Row>
                </Form>
              </div> */}
            </Row>
          </Container>
        </Modal.Body>

      </>
    );
  } else {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="flex-column loading-table">
              <div className="table-container">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={loadingProducts}
                  hover={true}
                  columns={loadingColumns}
                  striped={true}
                />
                <Pagination
                  totalRows={totalRows}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  selectNumberRows="no"
                />
              </div>
            </Row>
          </Container>
        </Modal.Body>

      </>
    );
  }

}

export default PickupList;
