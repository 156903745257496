
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processIds: JSON.parse(localStorage.getItem('processIds') || '[]'), 
};

const cognitivoSlice = createSlice({
  name: 'cognitivo',
  initialState,
  reducers: {
    // addProcessIds: (state, action) => {
    //   state.processIds = [...state.processIds, ...action.payload];
    //   localStorage.setItem('processIds', JSON.stringify(state.processIds));
    // },
    addProcessIds: (state, action) => {
        // Mapeia cada item da payload para incluir a data de início e de fim
        const newProcesses = action.payload.map((item: { processId: any; startDate: any; endDate: any; }) => ({
          processId: item.processId,
          startDate: item.startDate,
          endDate: item.endDate
        }));
  
        // Adiciona os novos processos ao estado
        state.processIds = [...state.processIds, ...newProcesses];
        
        // Atualiza o localStorage
        localStorage.setItem('processIds', JSON.stringify(state.processIds));
      },
    clearProcessIds: (state) => {
      state.processIds = [];
      localStorage.removeItem('processIds');
    },
  },
});

export const { addProcessIds, clearProcessIds } = cognitivoSlice.actions;
export default cognitivoSlice;
