import React, { useState } from "react";
import api from '../../../../services/api';

import { Dropdown, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableReportNfAudit.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import SupplierPaymentsByDay from "./components/SupplierPaymentsByDay/SupplierPaymentsByDay";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  exportBills: any;
  //printBills: any;
  actualDate: any;
  refreshData?: any;
}

const TableReportNfAudit: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading,
  exportBills,
  //printBills,
  actualDate, 
  refreshData
}: propTable) => {

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);

  const [paymentsByDayModalShow, setPaymentsByDayModalShow] = useState<any>(false);

  const showPaymentsByDay = (row: any) => {
    setSelectedSingleRow(row);
    setPaymentsByDayModalShow(true);
  }

  const {
    control,
    formState: { errors },
  } = useForm();

  function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
    const blob = `data:${filetype};base64,${file}`;

    let link = document.createElement('a');
    link.href = blob;//window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  };

  async function downloadInvoice(id: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
      const res = await api.post('SessionClosure/DownloadInvoicePdfAsync',
        {
          "itemId": id
        }, config
      );
      if (res.status !== 400) {
        //download file
        const dados = res.data.data;
        download(dados.file, dados.fileType, dados.fileName);
      }
    } catch (error: any) {
      //setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  }

  const handleDownloadInvoiceClick = (row: any) => {
    downloadInvoice(row.id);
  }

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon
                size="lg"
                icon={["fal", "ellipsis-h"]}
              />
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleDownloadInvoiceClick(row)} disabled={row.receber === 2 && row.dataPagamento ? false : true}>
                <FontAwesomeIcon
                  icon={["fal", "hand-holding-usd"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Pagamentos do Dia
              </Dropdown.Item>
            </Dropdown.Menu> */}
            <Dropdown.Menu>
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleDownloadInvoiceClick(row)} disabled={row.receber === 1 && row.numeroNota ? false : true}>
                <FontAwesomeIcon
                  icon={["fal", "file-pdf"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                /> Baixar Nota
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    if (!cell) return '';
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addPaymentDateButton(cell: any, row: any) {
    if (!cell) return '';
      
    return (
      <>
        <button className="btn btn-bills" onClick={() => showPaymentsByDay(row)}>
          {convertDate(cell, row)}
        </button>
      </>
    );
  }

  const columns = [
   
    { dataField: "session", text: "Session", sort: true },
    { dataField: "dataOperacao", text: "Data do Tour", sort: true, formatter: convertDate },
    { dataField: "formaPagamento", text: "Forma de Pag.", sort: true },
    { dataField: "adquirente", text: "Adquirente", sort: true },
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "contaContabil", text: "Conta Contabil", sort: true },
    { dataField: "clienteFornecedor", text: "Cliente/Fornecedor", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true },
    { dataField: "totalAdult", text: "ADT", sort: true },
    { dataField: "totalChild", text: "CHD", sort: true },
    { dataField: "totalInfant", text: "INF", sort: true },
    { dataField: "valorRecebido", text: "Valor Recebido", sort: true, formatter: convertValue },
    { dataField: "prorata", text: "Prorata fornecedor", sort: true, formatter: convertValue },
    { dataField: "receita", text: "Receita", sort: true, formatter: convertValue },
    { dataField: "numeroNota", text: "Número da Nota Fiscal", sort: true },
    { dataField: "valorNota", text: "Valor da Nota Fiscal", sort: true, formatter: convertValue },
    { dataField: "diferenca", text: "Diferença", sort: true, formatter: convertValue },
    { dataField: "idMov", text: "Id Mov.", sort: true },
    { dataField: "dataPagamento", text: "Data de pagamento do RM", sort: true, formatter: addPaymentDateButton },
    { dataField: "valorPago", text: "Valor Pago RM", sort: true, formatter: convertValue },
    { dataField: "id", text: "Ação", formatter: addActionButton }, 
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "sessions", text: "Sessions" }];

  const notFoundProducts = [{ contas: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando sessions", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusReserva === "Pendente") {
      return 'border-analise';
    } else if (row.statusReserva === "Confirmada") {
      return 'border-ativo';
    } else {
      return 'border-ativo';//'border-inativo';
    }
  };

  function handleReload() {
    //window.location.reload();
    setModalSuccess(false);
    refreshData();
  }

  if (billsReceive !== null) {
    return (
      <>
        <div className="table-default table-nf-audit">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  {/*<div>
                    <button className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        {/* Modal visualizar pagamentos fornecedor data */}
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={paymentsByDayModalShow}
          onHide={() => setPaymentsByDayModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <SupplierPaymentsByDay
            date={selectedSingleRow?.dataPagamento}
            supplierId={selectedSingleRow?.supplierId}
            supplierName={selectedSingleRow?.clienteFornecedor}
            setModalShow={setPaymentsByDayModalShow}
          />
        </Modal>
        {/* END - Modal visualizar pagamentos fornecedor data */}

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => { if (modalContent !== 'loading') setModalSuccess(false); }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableReportNfAudit;
