import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import ProductTour from "./ProductTour";
import { useAppDispatch } from "../../store/hooks";
import { listProduct, resetTour } from "../../store/product";
import { parseCookies, setCookie, destroyCookie } from 'nookies'
import { useLocation } from "react-router-dom";
import DefaultImage from '../../assets/img/image-default.png'

function EditTour() {
    const { pathname } = useLocation();
    const productAction:string = pathname.includes('clone') ? 'Clonar' : 'Editar';
    const isCloning:boolean = pathname.includes('clone');

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    /* Controle Redux  */

    const [defaultImageToken, setDefaultImageToken] = useState<any>(null);
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const [tourObject, setTourObject] = useState<any>({ isTranslate: 1 }); //objetão
    const [editTourObject, setEditTourObject] = useState<any>(null);
    //process.env.NODE_ENV === "development" -> ambiente de desenvolvimento
    const [management, setManagement] = useState(1);
    const [alreadyPassed, setAlreadyPassed] = useState(11);
    const productCode = window.location.href.split('productCode=')[1];

    const getIntegration = async (obj: any) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.get(`Dropdown/GetReservationSystemsBySupplierId/${obj.supplierId}`, config);
            if (data.statusCode !== 400) {
                if (data.data.length > 0) {
                    return data.data[0].id;
                }
            }
        } catch (error: any) {
            console.log(error)
            return '0';
        }
        return '0';
    }

    const transformObject = async (obj: any) => {

        let modalidade: any = obj.modalidade;

        let integrationAccessType: any = '2'; // sem integração de acesso
        let integrationPurchaseType: any = '0';
        let supplierIdentity: string | null = null;

        modalidade.forEach((elem: any) => {
            if (elem.referenceCode !== null && elem.referenceCode[0].text === null) {
                delete elem.referenceCode;
            }
            if (elem.haveAccessIntegration === 1) {
                integrationAccessType = '1';
            }
            if (elem.havePurchaseIntegration === 1) {
                if (elem.productPurchaseIntegration[0].idPurchaseIntegration === 2) {
                    //integrationPurchaseType = '9';
                    supplierIdentity = elem.productPurchaseIntegration[0].novaXs[0].supplierIdentity;
                } else if (elem.productPurchaseIntegration[0].idPurchaseIntegration === 3) {
                    //integrationPurchaseType = '8';
                }
            }
        });

        integrationPurchaseType = await getIntegration(obj);

        let aux: any = {
            "productCode": obj.productCode,

            /* Dados do tour */
            "productName": obj.productName,
            "minNumPeople": obj.minNumPeople,
            "maxNumPeople": obj.maxNumPeople,
            "sellingType": obj.sellingType,
            "productSubCategory": obj.productSubCategory,

            /* Operação */
            "operationType": obj.operationType,
            "site": obj.site,
            "app": obj.app,
            "b2b": obj.b2b,
            "marketplaces": obj.marketPlace,
            "repass": obj.repass,
            "correspondingOwnProduct": obj.correspondingOwnProduct,

            //Tipo de Operação Própria
            "resourcesId": obj.resourcesId,
            "ourVehicleType": obj.ourVehicleType,
            "ourRestaurantType": obj.ourRestaurantType,
            "ourTicketType": obj.ourTicketType,
            "sessionGroupId": obj.sessionGroupId,

            //Tipo de Operação Terceiros
            "supplierId": obj.supplierId,
            "supplierIdentity": supplierIdentity,

            //falta adicionar Integração
            "integrationPurchaseType": integrationPurchaseType,
            "integrationAccessType": integrationAccessType,

            /* Resumo e detalhes */
            "summary": obj.summary,
            "details": obj.details,

            /* Descrição e destaques */
            "description": obj.description,
            "highLight": obj.highLight,

            /* Fotos */
            "productUrl": obj.productUrl,
            "productImages": obj.productImages /* productImages */,

            /* Modalidade */
            "modalidade": modalidade,

            /* Tarifário */

            /* Localização */
            "eventLocation": obj.eventLocation,
            "tourLocationLat": obj.tourLocationLat,
            "tourLocationLong": obj.tourLocationLong,

            /* Instruções */
            "travellerInfoIsNeed": obj.travellerInfoIsNeed,
            "travellerInfoForAll": obj.travellerInfoForAll,
            "fullName": obj.fullName,
            "sex": obj.sex,
            "nationality": obj.nationality,
            "birthday": obj.birthday,
            "numDocument": obj.numDocument,
            "cellPhone": obj.cellPhone,
            "voucherInstruction": obj.voucherInstruction,

            /* SEO */
            "metaTitleBR": obj.metaTitleBR,
            "metaTitleEN": obj.metaTitleEN,
            "metaTitleES": obj.metaTitleES,
            "metaDescriptionBR": obj.metaDescriptionBR,
            "metaDescriptionEN": obj.metaDescriptionEN,
            "metaDescriptionES": obj.metaDescriptionES,
            "palavraChaveBR": obj.palavraChaveBR,
            "palavraChaveEN": obj.palavraChaveEN,
            "palavraChaveES": obj.palavraChaveES,
            "slug": obj.slug,
        }

        dispatch(listProduct(aux))
        setEditTourObject(aux);
        setTourObject(aux); //
        const cookies = parseCookies();
        if (cookies.idManagement) {
            setManagement(Number(cookies.idManagement));
        }
        destroyCookie(undefined, 'idManagement');
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await api.get(`Tour/GetNewProductTourByProductCode?productCode=${productCode}`, config);
                if (data.statusCode !== 400) {
                    if (data.data.log === 0) {
                        transformObject(data.data.data);
                    } else {
                        alert(data.data.texto);
                    }
                }
            } catch (error: any) {
                setTimeout(() => {
                    EditSupplier();
                }, 5000);
            }
        }

        EditSupplier();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])

    const handleNext = (newStep: any, newObject: any = {}) => {
        setTourObject({ ...tourObject, ...newObject })

        switch (newStep) {
            case 'startProduct':
                setManagement(0);
                break;
            case 'dataTour':
                if (alreadyPassed === 0) {
                    setAlreadyPassed(1);
                }
                setManagement(1);
                break;
            case 'operation':
                if (alreadyPassed === 1) {
                    setAlreadyPassed(2);
                }
                setManagement(2);
                break;
            case 'summaryAndDetails':
                if (alreadyPassed === 2) {
                    setAlreadyPassed(3);
                }
                setManagement(3);
                break;
            case 'descriptionsAndHigh':
                if (alreadyPassed === 3) {
                    setAlreadyPassed(4);
                }
                setManagement(4);
                break;
            case 'photo':
                if (alreadyPassed === 4) {
                    setAlreadyPassed(5);
                }
                setManagement(5);
                break;
            case 'modalities':
                if (alreadyPassed === 5) {
                    setAlreadyPassed(6);
                }
                setManagement(6);
                break;
            case 'tariffs':
                if (alreadyPassed === 6) {
                    setAlreadyPassed(7);
                }
                setManagement(7);
                break;
            case 'location':
                if (alreadyPassed === 7) {
                    setAlreadyPassed(8);
                }
                setManagement(8);
                break;
            case 'instructions':
                if (alreadyPassed === 8) {
                    setAlreadyPassed(9);
                }
                setManagement(9);
                break;
            case 'seo':

                if (alreadyPassed === 9) {
                    setAlreadyPassed(10);
                }
                setManagement(10);
                break;
            case 'finish':
                setAlreadyPassed(11);
                //insertTour();
                break;
            default:
                break;
        }
    };

    const updateTour = async (receivedData: any, isPhotoEdit: any = false) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
       
       
        setModalShow(true)

        let infoEdit: any;// = handleFinalObject();

        infoEdit = { ...editTourObject, ...receivedData };

        try {
            const { data } = await api.post('Tour/EditProductTour', infoEdit, config);
            if(data.data === null){
                setModalLog(1)
                setModalMessage(data.errorMessage)
            }
            if (data.statusCode !== 400) {
                if (data?.data?.log === 0) {
                    setCookie(null, 'idManagement', `${management}`, {
                        maxAge: (60),
                        path: '/',
                    })
                    handleRevokeCache();
                    setModalLog(data.data.log);
                    setModalMessage(isPhotoEdit ? "Alteração feita com sucesso. As mudanças feitas nas images podem demorar um pouco para refletir no banco" : data?.data?.texto);
                } else if (data?.data?.log === 1) {
                    setModalLog(1);
                    setModalMessage(data?.data?.texto || data?.errorMessage);
                }
            } else {
            }

        } catch (error: any) {

        }

        localStorage.removeItem('productTour');
    }

    useEffect(()=>{
        const convertImageToToken = async (data:any) => {            
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`File/UploadBase64?switchCaseParam=${pathname.includes('clone-tour') ? '1' : '5'}`,
                    [data]
                    , config);
                if (res.status !== 400) {
                    setDefaultImageToken(res.data.data.data[0])                    
                } 
            } catch (error: any) {
                
            }
                              
        }
        convertImageToToken(DefaultImage)
    },[])
    // Tour/TryinsertNewProductTour endpoint de inserção de tour

    const cloneTour = async (receivedData: any, isPhotoEdit: any = false) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };             
        const tariffObj:any = editTourObject.modalidade.map((mod:any) =>  {
            let aux:any = [];          
              mod?.tariff?.map((tariffItem:any) => {
                  aux.push({...tariffItem, tarCode: "", uniqueTarCode: "", gradeCode: "" })
                })                
              return aux
    });
        console.log(tariffObj)
        // const modalityObj = editTourObject.modalidade.map((mod:any) =>  ({ ...mod, tariff: [] }));        
        const modalityObj = editTourObject.modalidade.map((mod:any, i:number) =>  ({ ...mod,
             tariff: tariffObj[i],
             productCode: "",
             productModCode: "",
             sigIntegrationCode: "",
             uniqueModCode: "",
              }));        

        setModalShow(true);       

        let infoEdit: any;// = handleFinalObject();

        infoEdit = { ...editTourObject, modalidade: modalityObj, ...receivedData };
        delete infoEdit.productCode;
        if(isCloning) {            
            infoEdit.slug = [
                {
                    "text": infoEdit.slug[0].text + " (Cópia)",
                    "lang": 1
                },
                {
                    "text": infoEdit.slug[1].text + " (Copy)",
                    "lang": 2
                },
                {
                    "text": infoEdit.slug[2].text + " (Copiar)",
                    "lang": 3
                }
            ]
        infoEdit.productImages = [
            {
                "tokenImage": defaultImageToken,
                "picOrder": 1
            }
        ]
    }    

        try {
            const { data } = await api.post('Tour/TryinsertNewProductTour', infoEdit, config);
            if(data.data === null){
                setModalLog(1)
                setModalMessage(data.errorMessage)
            }
            if (data.statusCode !== 400) {
                if (data?.data?.log === 0) {
                    setCookie(null, 'idManagement', `${management}`, {
                        maxAge: (60),
                        path: '/',
                    })
                    handleRevokeCache();
                    setModalLog(data.data.log);
                    setModalMessage(isPhotoEdit ? "Alteração feita com sucesso. As mudanças feitas nas images podem demorar um pouco para refletir no banco" : data?.data?.texto);
                } else if (data?.data?.log === 1) {
                    setModalLog(1);
                    setModalMessage(data?.data?.texto || data?.errorMessage);
                }
            } else {
            }
    
        } catch (error: any) {
    
        }
    
        localStorage.removeItem('productTour');          
    }



    const receiveObject = async (data: any) => {

    }

    const handleRevokeCache = async () => {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/products/revokecache`);
            if (data.status !== 400) {

            }
        } catch (error: any) {

        }
    }

    const reload = () => {
        if (modalLog === 0) {
            window.location.href='/products/list-products';
        }
    };

    const handleSubmitFunction = () => {
        if(productAction === 'Editar'){
            return updateTour;
        }
        if(productAction === 'Clonar'){
            return cloneTour;
        }

    }

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={`Produtos / ${productAction} Tour`} />

                    <div className="d-flex align-items-center my-3">
                        <span
                            className="back m-0"
                            onClick={() => {
                                window.location.href = "/products/list-products";
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>


                    <Row className="add-product">
                        {/* MENU */}
                        {
                            management !== 0
                                ?
                                <Col md={3} lg={2}>
                                    <div className='card menu border-0'>
                                        <div className="list-menu">
                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Informações Básicas
                                                </span>
                                                <span className={management === 1 ? "list-menu-item item-action active" : management > 1 || 1 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 1 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (1 < alreadyPassed) { handleNext('dataTour', {}) } }}>
                                                        <p>Dados do tour</p>
                                                        {management > 1 || 1 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                        {/*ICONE DE CHECKED PARA QUANDO PASSAR PRO PROXIMO STEP <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]}/> */}
                                                    </div>
                                                </span>
                                                <span className={management === 2 ? "list-menu-item item-action active" : management > 2 || 2 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 2 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (2 < alreadyPassed) { handleNext('operation', {}) } }}>
                                                        <p>Operação</p>
                                                        {management > 2 || 2 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 3 ? "list-menu-item item-action active" : management > 3 || 3 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 3 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (3 < alreadyPassed) { handleNext('summaryAndDetails', {}) } }}>
                                                        <p>Resumo e Detalhes</p>
                                                        {management > 3 || 3 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 4 ? "list-menu-item item-action active" : management > 4 || 4 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 4 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (4 < alreadyPassed) { handleNext('descriptionsAndHigh', {}) } }}>
                                                        <p>Descrição e Destaques</p>
                                                        {management > 4 || 4 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>

                                                <span className={management === 5 ? "list-menu-item item-action active" : management > 5 || 5 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 5 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (5 < alreadyPassed) { handleNext('photo', {}) } }}>
                                                        <p>Fotos</p>
                                                        {management > 5 || 5 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Opções e Preços
                                                </span>
                                                <span className={management === 6 ? "list-menu-item item-action active" : management > 6 || 6 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 6 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (6 < alreadyPassed) { handleNext('modalities', {}) } }}>
                                                        <p>Modalidades</p>
                                                        {management > 6 || 6 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 7 ? "list-menu-item item-action active" : management > 7 || 7 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 7 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (7 < alreadyPassed) { handleNext('tariffs', {}) } }}>
                                                        <p>Tarifários</p>
                                                        {management > 7 || 7 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Conteúdo
                                                </span>
                                                <span className={management === 8 ? "list-menu-item item-action active" : management > 8 || 8 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 8 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (8 < alreadyPassed) { handleNext('location', {}) } }}>
                                                        <p>Localização</p>
                                                        {management > 8 || 8 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 9 ? "list-menu-item item-action active" : management > 9 || 9 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 9 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (9 < alreadyPassed) { handleNext('instructions', {}) } }}>
                                                        <p>Instruções </p>
                                                        {management > 9 || 9 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 10 ? "list-menu-item item-action active" : management > 10 || 10 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 10 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (10 < alreadyPassed) { handleNext('seo', {}) } }}>
                                                        <p>SEO </p>
                                                        {management > 10 || 10 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </Col>
                                :
                                <></>
                        }

                        <ProductTour receiveObject={receiveObject} tourObject={{ isTranslate: 1 }} handleNext={handleNext} management={management} isEdit={true} editFunction={handleSubmitFunction()} />
                    </Row>
                </div>
                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={() => {
                        if (modalLog === 0) {
                            reload();
                        }
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                            style={{ height: "600px" }}
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    )}
                                </div>
                            )}
                            <div>{modalMessage}</div>
                            {modalLog !== null &&
                                <div className="d-flex justify-content-center pt-3">
                                    <button
                                        onClick={() => {
                                            reload();
                                            setModalLog(null);
                                            setModalMessage("Carregando");
                                            setModalShow(false);
                                        }}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>
        </>
    )
}

export default EditTour;