import React, { useEffect, useState, Key } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import api from "../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

//STYLES
import "./TableCognitivoResponsive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";

import CreateSession from "./components/CreateSession/CreateSession";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import ModalSessionMessages from "../../../../components/Modal/ModalSessionMessages/ModalSessionMessages";
import { Table, Tbody, Td, Th, Tr } from "react-super-responsive-table";
import ExecuteAIModel from "../ExecuteAIModel/ExecuteAIModel";

export interface propTable {
  sessionObj: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  startCognitivoModel: any;
  progressAnalisys: any;
  loading: any;
}

const TableCognitivoResponsive: React.FC<propTable> = ({
  sessionObj,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  startCognitivoModel,
  progressAnalisys,
  loading,
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState<any>(false);
  
  const [sessionSelected, setSessionSelected] = useState<any>(null);
  const [sessionDeleteModalShow, setSessionDeleteModalShow] =
  useState<any>(false);
  
  const [sessionMessagesId, setSessionMessagesId] = useState<any>(null);
  const [sessionMessageList, setSessionMessageList] = useState<any>([]);
  const [sessionMessagesShow, setSessionMessagesShow] = useState<any>(false);
  const [width, setWidth] = useState<any>(window.innerWidth);
  
  /* Success Modal (Criar Session) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Criar Session) */
  
  /* Modal Coginitivo */
  const [aiModelSuccess, setAiModelSuccess] = useState<boolean>(false);
 const [modalCognitivoOpen, setModalCognitivoOpen] = useState<boolean>(false);
 const [cognitivoSessionId, setCognitivoSessionId] = useState<string>('');  
 /* END - Modal Coginitivo */

 /* FUNÇÕES */

 function handleReload() {
   window.location.reload();
 }

 useEffect(() => {
  if(aiModelSuccess === true) handleReload()
 }, [aiModelSuccess])

 function convertDate(cell: any, row: any) {
   if (cell !== null) {     
     var aux: any = cell.split("T")[0].split("-");
     var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

     return (
       <>
         <div
           className="d-flex align-items-center"
           style={{ flexDirection: "column" }}
         >
           <span>{date}</span>
           <span>{row.horaOperacao}</span>
         </div>
       </>
     );
   } else {
     return <></>;
   }
 }

 function handleActionSessionDeleteModal(e: any) {
   setModalContent("loading");
   setModalSuccess(true);

   const salvar = async () => {
     const token = localStorage.getItem("GroupId") || "{}";
     const config = {
       headers: { Authorization: `Bearer ${token}` },
     };
     try {
       const { data } = await api.post(
         "Manifest/DeleteSession",
         {
           sessionid: sessionSelected.sessionReloc,
         },
         config
       );
       if (data.status !== 400) {
         var response = data.data;

         setResponseText(response.texto);
         setLog(response.log);

         if (response.log !== 1) {
           setModalContent("success"); // <----- nada apareceu, so excluiu

           setSessionDeleteModalShow(false);
           setSessionSelected(null);
         } else {
           setModalContent("error");
         }
       }
     } catch (error: any) {
       if (error?.response?.status === 401) {
         window.location.href = window.location.origin + "/";
       }
       if (error?.response?.status === 400) {
         //window.location.href = window.location.origin + '/session-closure';
       }
       //;
     }
   };
   salvar();
 }


 const getSessionMessages = async (sessionId: any) => {
   const token = localStorage.getItem('GroupId') || '';

   const config = {
     headers: { 'Authorization': `Bearer ${token}` },
   };
   try {

       const { data } = await api.post(`Manifest/GetSessionMessagesAsync`, {
         "sessionId": sessionId
       }, config);
       if (data.status !== 400) {
           var response = data.data;

           setSessionMessageList(response);
           setSessionMessagesShow(true);
       }   
   }catch(error: any) {
       if (error?.response?.status === 401) {
           window.location.href = window.location.origin + '/';              
       }
       if (error?.response?.status === 400) {
           //window.location.href = window.location.origin + '/session-closure';              
       }
       //;
   }
 }
 

 const columns =[
  {
    dataField: "sessionId",
    text: "ID da Session"
  },
  {
    dataField: "produto",
    text: "Produto"
  },
  {
    dataField: "vagasUsadas",
    text: "Vagas Usadas"
  },
  {
    dataField: "vagasDisponiveis",
    text: "Vagas Disponiveis"
  },
  {
    dataField: "dataSession",
    text: "Data da Session",
    formatter: convertDate
  },
 ]


 function loadingFunc() {
   return (
     <>
       <div className="animated-background row-loading"></div>
     </>
   );
 }

 const loadingColumns = [
   {
     dataField: "contasd",
     text: "Carregando Sessões",
     formatter: loadingFunc,
   },
 ];

 const loadingProducts = [
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
 ];
 /* [END] loading table */

 /* In case of empty table*/
 const notFoundColumns = [{ dataField: "sessoes", text: "Sessões" }];

 const notFoundProducts = [{ sessoes: "Nenhuma sessão encontrada" }];
 /* [END] In case of empty table*/

 

 /* FORMULÁRIO */

 const { reset } = useForm();

 useEffect(() => {
   if (modalShow === false) {
     reset({
       data: undefined,
     });
   }
 }, [reset, /*  */ modalShow]);

 useEffect(() => {
   //tablesizing
   window.addEventListener("resize", function () {
     var newWidth = window.innerWidth;
     setWidth(newWidth);
   });
 }, []);

  /* RENDER */
  if (sessionObj !== null) {
    return (
      <>
        <div className="table-default cognitivo-table">
          <div className="table-container">
            <div>
              <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="btn btn-bills"
                    onClick={startCognitivoModel}
                  >
                    <FontAwesomeIcon
                      icon={["fal", "play"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Iniciar modelo da Cognitivo
                  </div>
                </div>
                <div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="btn btn-bills"
                    onClick={progressAnalisys}
                  >
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "chart-line"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Análise de progresso
                  </div>
                </div>
               
              </div>
            </div>
          { width > 640 ?

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessionObj}
              columns={columns}
              striped={true}
            />
            :
            <div className="table-mobile my-4">
              <Table className="table table-bordered">
               
                <Tbody>
                  {sessionObj.map((elem: any, index: any) => {
                    return (
                      <Tr >
                        <Th key={elem.idNewItems}>
                          <Td>
                            <strong>{columns[0].text}:</strong> &nbsp;
                            {elem.sessionId}
                          </Td>
                          <Td>
                            <strong>{columns[1].text}:</strong> &nbsp;{" "}
                            {elem.produto}
                          </Td>
                          <Td>
                            <strong>{columns[2].text}:</strong> &nbsp;{" "}
                            {elem.vagasUsadas}
                          </Td>
                          <Td>
                            <strong>{columns[3].text}:</strong> &nbsp; {elem.vagasDisponiveis}
                          </Td>
                          <Td>
                            <strong>{columns[4].text}:</strong> &nbsp;{" "}
                            {elem.dataSession.split('T')[0].split('-').reverse().join('/')}
                          </Td>
                          </Th>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            }

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className="modal-body text-center sucess-pos">
                  {modalContent !== "loading" ? (
                    <>
                      <div>
                        {log === 1 || log === "1" ? (
                          <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : log === 0 || log === "0" ? (
                          <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={["fal", "question-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        )}
                      </div>
                      <div>{responseText}</div>
                      <div className="d-flex justify-content-center">
                        {modalContent === "success" ? (
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : modalContent === "ok" ? (
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : (
                          <button
                            onClick={() => setModalShow(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="modal-body">
                      <div className="text-center">
                        <div className="load"></div>
                      </div>
                      <div>
                        <span>Processando</span>
                      </div>
                      <div></div>
                    </div>
                  )}
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}

            {/* modal delete session */}
            <ModalQuestion
              modalShow={sessionDeleteModalShow}
              setModalShow={setSessionDeleteModalShow}
              titulo="Excluir Session"
              mensagem="Tem Certeza que deseja excluir esta session?"
              handleActionConfirmModal={handleActionSessionDeleteModal}
            />
            {/* END - modal delete session */}

            {/* modal mensagens session */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={sessionMessagesShow}
              onHide={() => setSessionMessagesShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header className="p-3" closeButton></Modal.Header>
                <ModalSessionMessages
                  sessionId={sessionMessagesId}
                  show={setModalSuccess}
                  setResponseText={setResponseText}
                  setModalContent={setModalContent}
                  setLog={setLog}
                  messageList={sessionMessageList}
                  getSessionMessages={getSessionMessages}
                />
              </>
            </Modal>
            {/* END - modal mensagens session */}

            {/* START - modal Cognitivo */}
            <ExecuteAIModel
              modalOpen={modalCognitivoOpen}
              setModalOpen={setModalCognitivoOpen}
               _sessionId={cognitivoSessionId} 
               setSuccess={setAiModelSuccess}
               />
            {/* END - modal Cognitivo */}
          </div>
        </div>
      </>
    );
  } else if (sessionObj === null && loading === true) {
    return (
      <>
        <div className="table-default manifest-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default manifest-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
};

export default TableCognitivoResponsive;
