import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from 'react-i18next';

import bg00 from "../../assets/img/backgrounds/Bg-Arraial.jpg";
import bg01 from "../../assets/img/backgrounds/Bg-Login.jpg";
import bg02 from "../../assets/img/backgrounds/Bg-Aves.jpg";
import bg03 from "../../assets/img/backgrounds/Bg-Escadaria.jpg";
import bg04 from "../../assets/img/backgrounds/Bg-Foz.jpg";
import bg05 from "../../assets/img/backgrounds/Bg-Lapa.jpg";
import bg06 from "../../assets/img/backgrounds/Bg-Mergulho.jpg";
import bg07 from "../../assets/img/backgrounds/Bg-NewPass.jpg";
import bg08 from "../../assets/img/backgrounds/Bg-Paraty.jpg";
import bg09 from "../../assets/img/backgrounds/Bg-Profile.jpg";

//import LanguageLoginMenu from "../../components/LanguageLoginMenu/LanguageLoginMenu";
import AuthLogin from "./components/AuthLogin/AuthLogin";
import { Link } from "react-router-dom";

import "./Login.scss";

function Login() {
  const { t } = useTranslation();

  const bgRandom = Math.floor(Math.random() * 10);

  const randomBanners: any = {
    0:  bg00,
    1:  bg01,
    2:  bg02,
    3:  bg03,
    4:  bg04,
    5:  bg05,
    6:  bg06,
    7:  bg07,
    8:  bg08,
    9:  bg09
  }

  useEffect(()=>{
    i18next.changeLanguage("pt-BR")   
  },[])

  return (
    <Container fluid className="container-login p-0">
      <Row className="h-100 w-100 m-0">
        <Col
          sm={12}
          className="bg-login-image p-0"
          id="random"
          style={{ backgroundImage: `url(${randomBanners[bgRandom]})` }}
        >
          <div className="split-screen">
            <Row className="h-100 w-100 m-0 content-split">
              <Col className="logo-login" sm={12} md={6}>
                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={process.env.REACT_APP_SERVER_LOGO_WHITE}
                    alt="C2SIG"
                  />
                </div>
                {/* <LanguageLoginMenu /> */}
              </Col>
              <Col
                sm={12}
                md={6}
                className="bg-white valign left p-0 my-auto rounded"
              >
                <div className="inner d-flex flex-column justify-content-between h-100">
                  <div>
                    <h1>{t("login.title")}</h1>
                    <p>{t("login.subTitle")}</p>
                  </div>
                  <div>
                    <AuthLogin />
                    <hr style={{ backgroundColor: "#ced4da", opacity: "1" }} />
                    <div className="text-center link-login">
                      <a href='https://agents.c2rio.travel/home' target="_blank" className="btn-register" rel="noreferrer">
                      <button
                        type="submit"
                        className="btn btn-primary "
                      >
                        {t('login.register')}
                      </button>
                      </a>
                      <Link
                        className="small text-primary"
                        to="/forgot_password"
                      >
                        {t("login.forgotPass")}
                      </Link>
                    </div>
                  </div>
                  <div className="text-center" style={{ fontSize: "10px" }}>
                    <small>{t("login.development")}</small>
                    <div className="login-brand d-flex align-items-center justify-content-center mt-1">
                      <img
                        src={process.env.REACT_APP_SERVER_LOGO}
                        alt="C2SIG"
                        className="float-left"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
