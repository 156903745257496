import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ChartSalesReport from './components/ChartSalesReport/ChartSalesReport';
import FilterSalesReport from './components/FilterSalesReport/FilterSalesReport';
import TableSalesReport from './components/TableSalesReport/TableSalesReport';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Style
import './SalesReport.scss';
import { Button, Modal } from 'react-bootstrap';

function SalesReport() {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);
    const [infoChart, setInfoChart] = useState<any>(null);
    const [filterBySelected, setFilterBySelected] = useState<any>(t("salesReport.month"));
    const [loading, setLoading] = useState<any>(false);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalResponse, setModalResponse] = useState<any>('');
    const [modalLog, setModalLog] = useState<any>(null);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const [allowSearch, setAllowSearch] = useState<any>(1);
    /* END - Pagination */

    const [actualType, setActualType] = useState<any>(undefined);
    const [actualDateType, setActualDateType] = useState<any>(null);
    const [actualDate, setActualDate] = useState<any>([]);
    const [actualReservationStatus, setActualReservationStatus] = useState<any>();
    const [actualPaymentStatus, setActualPaymentStatus] = useState<any>();
    const [actualChannel, setActualChannel] = useState<any>();
    const [actualPromoter, setActualPromoter] = useState<any>();
    const [actualClient, setActualClient] = useState<any>();
    const [actualUser, setActualUser] = useState<any>();
    const [actualProduct, setActualProduct] = useState<any>();
    const [actualFilter, setActualFilter] = useState<any>();

    
    const [loadButton, setLoadButton] = useState<any>([false, false])

    useEffect(() => {
        var today: any = new Date();

        /* buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 0, 0, 1, 0, 0, 0, '', ''); */
        buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '0', 3);
        //buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function buscar(type: any, dateType: any, date: any, reservationStatus: any, paymentStatus: any, channel: any, promoter: any, client: any, user: any, product: any, filter: any) {
        
        setLoading(true);
        setFilterBySelected(filter === '' ? t("salesReport.month") : filter === '1' ? t("salesReport.year") : t("salesReport.day"));
        setActualType(type);
        setActualDateType(dateType);
        setActualDate(date);
        setActualReservationStatus(reservationStatus);
        setActualPaymentStatus(paymentStatus);
        setActualChannel(channel);
        setActualPromoter(promoter);
        setActualClient(client);
        setActualUser(user);
        setActualProduct(product);
        setActualFilter(filter);

        if (pageCount !== 1) {
            setPageCount(1);
        } else {
            setAllowSearch(allowSearch + 1);
        }

    }
   
    useEffect(() => {
        async function changingPage() {
            setLoading(true);
            let beginDate = []
            let endDate = []

            if (actualDate?.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate?.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();
            

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            const body = {
                "page": pageCount,
                "rowsPerPage": rowsPerPage,
                "type": actualType === '' ? 0 : actualType,//1 = tour | 2 = transfer | 4 = ticket,
                "dateType": actualDateType === '' ? 1 : parseInt(actualDateType),//1 = Data da Reserva | 2 = Data da Operação,
                "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                "reservationStatus": actualReservationStatus === '' ? 0 : parseInt(actualReservationStatus),//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva| 9 = pagamento não aprovado",
                "paymentStatus": actualPaymentStatus === '' ? 0 : actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                "channel": actualChannel ? parseInt(actualChannel) : 0, //1,
                "promoter": actualPromoter === '' ? 0 : actualPromoter,//user_code tab promoter, ''
                "affiliate": actualClient === '' ? 0 : actualClient,//supplier_identity, ''
                "user": actualUser === '' ? 0 : actualUser, //''
                "product": actualProduct === '0' ? '' : actualProduct === undefined ? '' : actualProduct,//"product_cod tab prod_modalidades"
                "filter": actualFilter === '' ? 3 : parseInt(actualFilter),//"1 = Ano, 2 = Mês, 3 = Dia"
            }

            try {
                const { data } = await api.post('/Report/ManagementReport', body
                    , config);

                if (data.status !== 400) {
                    setLoading(false);
                    if(data.data.log === 1){
                        setModalResponse(data.data.texto);
                        setModalLog(1);
                        setModalShow(true);
                    } else {
                        setInfoChart(data.data.managementReportG);
                        setInfo(data.data.rows);
                        setTotalRows(data.data.rowsCount);
                    }
                }
            } catch (error: any) {
                setLoading(false);
            }
        }
        if (actualDateType !== null) {
            changingPage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage, allowSearch])

    const printReport = () => {
        setLoadButton([true, false]);
        async function printing() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/Report/ManagementReportPDF',
                    {
                        "page": 0,
                        "rowsPerPage": 0,
                        "type": actualType,//1 = tour | 2 = transfer | 4 = ticket,
                        "dateType": actualDateType,//1 = Data da Reserva | 2 = Data da Operação,
                        "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "reservationStatus": actualReservationStatus === '' ? 0 : parseInt(actualReservationStatus),//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva| 9 = pagamento não aprovado",
                        "paymentStatus": actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                        "channel": actualChannel ? parseInt(actualChannel) : 0, //1,
                        "promoter": actualPromoter,//user_code tab promoter, ''
                        "affiliate": actualClient,//supplier_identity, ''
                        "user": actualUser, //''
                        "product": actualProduct === '0' ? '' : actualProduct === undefined ? '' : actualProduct.value,//"product_cod tab prod_modalidades"
                        "filter": actualFilter === '' ? 2 : actualFilter,//"1 = Ano, 2 = Mês, 3 = Dia"
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });

                if (exp.status !== 400) {
                    setLoadButton([false, false]);
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.pdf',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                    setModalResponse(t("salesReport.printSuccess"));
                    setModalLog(0);
                } else {
                    setModalResponse(t("salesReport.printError"));
                    setModalLog(1);

                }
            } catch (error: any) {
                setModalResponse(t("salesReport.printError"));
                setModalLog(1);

            }
        }
        printing();
    }

    const exportReport = () => {
        setLoadButton([false, true]);
        async function exporting() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/Report/ManagementReportExcel',
                    {
                        "page": 0,
                        "rowsPerPage": 0,
                        "type": actualType,//1 = tour | 2 = transfer | 4 = ticket,
                        "dateType": actualDateType,//1 = Data da Reserva | 2 = Data da Operação,
                        "dateI": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dateF": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "reservationStatus": actualReservationStatus === '' ? 0 : parseInt(actualReservationStatus),//"'' = Todas|1 = confirmado|2 = cancelado|7 = pre reserva| 9 = pagamento não aprovado",
                        "paymentStatus": actualPaymentStatus,//1 = Pagos | 2 = Pendentes,
                        "channel": actualChannel ? parseInt(actualChannel) : 0, //1,
                        "promoter": actualPromoter,//user_code tab promoter, ''
                        "affiliate": actualClient,//supplier_identity, ''
                        "user": actualUser, //''
                        "product": actualProduct === '0' ? '' : actualProduct === undefined ? '' : actualProduct.value,//"product_cod tab prod_modalidades"
                        "filter": actualFilter === '' ? 2 : actualFilter,//"1 = Ano, 2 = Mês, 3 = Dia"
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });
                   
                if (exp.status !== 400) {
                    setLoadButton([false, false]);
                    setModalResponse(t("salesReport.exportSuccess"));
                    setModalLog(0);
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.xlsx',
                        );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                } else {
                    setModalResponse(t("salesReport.exportError"));
                    setModalLog(1);
                }

            } catch (error: any) {
                setModalResponse(t("salesReport.exportError"));
                setModalLog(1);
            }
        }
        exporting();
    }

    // actualProduct.value
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard sales-report">
                    <Breadcrumb title={t("salesReport.title")} />
                    <div className="suppliers mt-4 d-flex justify-concolumntent-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>{t("salesReport.title")}</h2>
                    </div>

                    <FilterSalesReport buscar={buscar} />

                    {totalRows > 0
                        ?
                        <ChartSalesReport infoChart={infoChart} filter={filterBySelected} loading={loading} t={t} />
                        :
                        <></>
                    }

                    <TableSalesReport info={info} loading={loading} printReport={printReport} exportReport={exportReport} totalRows={totalRows} pageCount={pageCount} setPageCount={setPageCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} loadButton={loadButton} t={t} />

                    <Modal
                        className="modal-confirm"
                        show={modalShow === true}
                        onHide={() => {
                            setModalLog(null);
                            setModalShow(false);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        {modalLog !== null
                            ?
                            <div className='modal-body text-center'>
                                <div>
                                    {modalLog === 0
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em"}}
                                        />
                                    }
                                </div>

                                <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                    {modalResponse}
                                </div>
                                <div>
                                    <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setModalShow(false)}>{t("salesReport.close")}</Button>
                                </div>
                            </div>
                            :

                            <div className="modal-body loading-modal">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <span>{t("salesReport.loading")}</span>
                                </div>
                                <div></div>
                            </div>
                        }
                    </Modal>
                </div>
            </DesktopDefault>
        </>

    );
}

export default SalesReport;