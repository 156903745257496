import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './OTAImport.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterOTAImport from './components/FilterOTAImport/FilterOTAImport'; 
import TableOTAImport from './components/TableOTAImport/TableOTAImport';

function OTAImport() {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;


    const [actualDate, setActualDate] = useState<any>([todayStr]);

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function listBills() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try { 
                const { data } = await api.post('/BillsReceivable/GetImportOTALogReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsReceive(data.data.rows);
                }
            } catch (error: any) {
                //if (error.response.status === 401) {
                //    window.location.href = window.location.origin + '/';
                //}
            }
        }
        listBills();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(
        date: any
    ) {

        setActualDate(date);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/BillsReceivable/GetImportOTALogReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setError(true)
            //if (error?.response?.status === 401) {
            //    window.location.href = window.location.origin + '/';
            //}
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Importar Planilha OTA'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Importar Planilha OTA</h2>
                    </div>
                    <FilterOTAImport
                        buscar={buscar}
                        billsReceive={billsReceive}
                        error={error}
                    />
                    <TableOTAImport
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        //exportBills={exportBills}
                        //printBills={printBills}
                        loading={loading}
                        //totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        parameters={parameters}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default OTAImport;