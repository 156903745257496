import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './Cognitivo.scss';
import TableCognitivoResponsive from './components/TableCognitivoResponsive/TableCognitivoResponsive';
import FilterCognitivo from './components/FilterCognitivo/FilterCognitivo';
import { useAppDispatch } from '../../store/hooks';
import { addProcessIds } from '../../store/cognitivo';
import ModalProgress from './components/ModalProgress/ModalProgress';
import ModalStartModel from './components/ModalStartModel/ModalStartModel';

function Cognitivo() {
    const [sessionObj, setSessionObj] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    //Start do modelo da cognitivo
    const [modalStartModelOpen, setModalStartModelOpen] = useState<boolean>(false);
    const [loadingStartModel, setLoadingStartModel] = useState<boolean>(false);
    const [startModelObj, setStartModelObj] = useState<any>(null);
    const [startModelMsg, setStartModelMsg] = useState<string>('');    
    //Start do modelo da cognitivo
    
    //Analise do progresso
    const [modalProgressOpen, setModalProgressOpen] = useState<boolean>(false);
    const [loadingCognitivoProgress, setLoadingCognitivoProgress] = useState<boolean>(false);
    const [cognitivoProgressObj, setCognitivoProgressObj] = useState<any>(null);
    //Analise do progresso


    //FILTRO
    const [actualCod, setActualCod] = useState<any>();
    const [actualDate, setActualDate] = useState<any>([]);
    const [actualType, setActualType] = useState<any>();
    const [actualOperation, setActualOperation] = useState<any>();

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const dispatch = useAppDispatch();

    const sessionjson = [{
        "sessionId": 89680,
        "produto": "teste tour 1 (sem grupo de sessão - 25/08/2024)",
        "vagasUsadas": 2,
        "vagasDisponiveis": 10,
        "hora": "00:00:00",
        "horaSession": "00:00:00",
        "dataSession": "2025-02-01T00:00:00"
    },
    {
        "sessionId": 89682,
        "produto": "teste tour 1 (sem grupo de sessão - 25/08/2024)",
        "vagasUsadas": 1,
        "vagasDisponiveis": 11,
        "hora": "01:00:00",
        "horaSession": "00:00:00",
        "dataSession": "2025-02-01T00:00:00"
    }]

    function clearActualValues() {
        setActualCod("");
        setActualDate([]);
        setActualType(null);
        setActualOperation("");
    }

    async function buscar(date: any) {
        setSessionObj(null);
        setLoading(true);
        setPageCount(1);     
        setActualDate(date);
        

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            const res = await api.post('/Integration/Cognitivo/GetAllSessionsFilterByDate',
                {                    
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}T00:00:00` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T00:00:00`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}T23:59:59` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}T23:59:59` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T23:59:59`
                }, config
            );
            if (res.status !== 400) {
                console.log(res.data)
                setLoading(false);
                setSessionObj(res.data.data);
                // setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
        }
    };

    useEffect(() => {
        setSessionObj(null);
        setLoading(true);
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function getSessions() {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };
                
                const res = await api.post('/Integration/Cognitivo/GetAllSessionsFilterByDate',
                    {                       
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}T00:00:00` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T00:00:00`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}T23:59:59` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}T23:59:59` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T23:59:59`
                    }, config
                );
                if (res.status !== 400) {
                    setLoading(false);                    
                    setSessionObj(res.data.data)
                    setTotalRows(res.data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
            }
        }
        getSessions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage])

    // const startCognitivoModel = () => {
    //     const newProcesses = [
    //       { "processId": "458139e0-58eb-2892-fe50-649914445f71"},
    //     ];
    
    //     dispatch(addProcessIds(newProcesses));
    //   };

    function extractProcessId(errorMessage:string) {
        const regex = /(\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b)/;
        const match = errorMessage.match(regex);
        
        if (match) {
            return match[0];
        } else {
            return 'No ID found';
        }
    }

    async function startCognitivoModel() { 
        setModalStartModelOpen(true);
        setLoadingStartModel(true); 

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }
        let today: any = new Date();
        let startDateReq:string =(actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        let endDateReq: string = (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            
            const data = await api.post('/Integration/Cognitivo/Model/Start',
                {    
                    // "startDate": startDateReq,
                    // "endDate": endDateReq,              
                    "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}T00:00:00` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T00:00:00`,
                    "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}T23:59:59` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}T23:59:59` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T23:59:59`
                }, config

            );
            if (data.status !== 400) {
                let processObj:any = [{...data.data.data, startDate: startDateReq, endDate: endDateReq}];
                setStartModelObj(processObj);
                dispatch(addProcessIds(processObj));
                setLoadingStartModel(false)
                const phrase: string = `Modelo para a data de ${startDateReq.split('-').reverse().join('/')} à ${endDateReq.split('-').reverse().join('/')}  já iniciado com id de processo: ${data.data.data.processId}`
                setStartModelMsg(phrase); 
            }
        } catch (error: any) {
            setLoadingStartModel(false)
            if(error.response.data.data !== null){
            const phrase: string = `Modelo para a data de ${startDateReq.split('-').reverse().join('/')} à ${endDateReq.split('-').reverse().join('/')}  já iniciado com id de processo: ${extractProcessId(error.response.data.errorMessage)}`
            setStartModelMsg(phrase);  
            }else{
                const phrase: string = `Erro ao processar operação!`
                setStartModelMsg(phrase);  
            }  
        }
    };

    async function progressAnalisys() {
        setModalProgressOpen(true);
        setLoadingCognitivoProgress(true);

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        let aux = [];
        const storagedProcesses = JSON.parse(localStorage.getItem('processIds') || '[]');
        for (let i = 0; i < storagedProcesses.length; i++){
           try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            
            const data = await api.post('/Integration/Cognitivo/Model/GetStatus',
                {
                    "processId" : storagedProcesses[i].processId
                }, config
            );
            if (data.status !== 400) {              
                aux.push(data.data.data);
            }
        } catch (error: any) {            
            if (error?.response?.status === 401) {
                
            }
        }

        }
        if(aux.length !== 0) {
            setCognitivoProgressObj(aux)
            setLoadingCognitivoProgress(false)
        }else{
            setLoadingCognitivoProgress(false)
        }

       
    };
    console.log(cognitivoProgressObj)

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Operações / Cognitivo'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Cognitivo</h2>
                    </div>
                    <FilterCognitivo
                        buscar={buscar}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={false/* allowLoadFromFilter */}
                    />
                    <TableCognitivoResponsive
                        sessionObj={sessionObj}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        startCognitivoModel={startCognitivoModel}
                        progressAnalisys={progressAnalisys}
                        loading={loading}
                    />
                <ModalProgress
                    cognitivoProgressObj={cognitivoProgressObj}
                    setCognitivoProgressObj={setCognitivoProgressObj}
                    loadingCognitivoProgress={loadingCognitivoProgress}
                    setLoadingCognitivoProgress={setLoadingCognitivoProgress}
                    modalProgressOpen={modalProgressOpen} 
                    setModalProgressOpen={setModalProgressOpen} />

                <ModalStartModel
                    ModalStartModelOpen={modalStartModelOpen}
                    setModalStartModelOpen={setModalStartModelOpen}
                    message={startModelMsg}
                    setMessage={setStartModelMsg}
                    loadingStartModel={loadingStartModel}
                    startModelObj={startModelObj}
                    />
                </div>


            </DesktopDefault>
        </>

    );
}

export default Cognitivo;