/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../../../services/api';

import { AccordionContext, Accordion, useAccordionButton, Modal, Row } from 'react-bootstrap';

import EditPersonalData from '../../../components/EditDataFields/EditPersonalData/EditPersonalData'
import EditFinancial from '../../../components/EditDataFields/EditFinancial/EditFinancial'
import EditDocuments from '../../../components/EditDataFields/EditDocuments/EditDocuments'
import EditBankData from '../../../components/EditDataFields/EditBankData/EditBankData'
import EditUsers from '../../../components/EditDataFields/EditUsers/EditUsers'

import AccordionPersonal from "../../AccordionPersonal/AccordionPersonal";
import AccordionDocuments from "../../AccordionDocuments/AccordionDocuments";
import AccordionFinancial from '../../AccordionFinancial/AccordionFinancial';
import AccordionAccount from '../../AccordionAccount/AccordionAccount';
import AccordionUsers from '../../AccordionUsers/AccordionUsers';
import RequestResponseModal from '../../../../../components/Modal/RequestResponseModal/RequestResponseModal';
import EditPartnersLog from '../../EditPartnersLog/EditPartnersLog';
import './EditPartnersDataFields.scss';

//import ImageDefault from "../../../../../assets/img/image-default.png";

export interface propInfo {
    info: any;
};

const EditDataFields: React.FC<propInfo> = ({
    info
}: propInfo) => {
    const [stepCtrl, setStepCtrl] = useState<number>(0);
    const [personalDataEdit, setPersonalDataEdit] = useState<boolean>(true);
    const [financialEdit, setFinancialEdit] = useState<boolean>(true);
    const [documents, setDocuments] = useState<boolean>(true);
    const [bankDataEdit, setBankDataEdit] = useState<boolean>(true);
    const [usersEdit, setUsersEdit] = useState<boolean>(true);
    const [showModalDocument, setShowModalDocument] = useState<boolean>(false);
    const [document, setDocument] = useState<any>(null);
    const [documentName, setDocumentName] = useState<any>('');
    const [showLog, setShowLog] = useState<any>(null);
    const [log, setLog] = useState<any>(null);
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, setModalText] = useState<any>(''); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [allowRedirect, setAllowRedirect] = useState<boolean>(false);
    
    const idPartner = window.location.href.split('id=')[1];

    const showDocument = (doc: any, docName: any) => {
        setDocument(doc);
        setDocumentName(docName);
        setShowModalDocument(true);
    }

    const handleNext = () => {
        setStepCtrl(stepCtrl + 1);
    }

    const ContextAwareToggle = ({ children, eventKey, callback, status }: any) => {

        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <h2 className="accordion-custom accordion-header">
                <button
                    type="button"
                    aria-expanded="false"
                    className={isCurrentEventKey ? "accordion-button collapsed" : "accordion-button collapsed"}
                    onClick={decoratedOnClick}
                    disabled={status}>
                    {children}
                </button>
            </h2>
        );
    }

    function scrollTop() {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        scrollTop();
    }, [personalDataEdit, financialEdit, documents, bankDataEdit, usersEdit])

    const editPartners = (obj: any) => {
        setModalResponse("loading");

        if(obj.compType == '1'){ //pessoa fisica nacional
            obj.cnpj = "";
            obj.code = "";
        }
        if(obj.compType == '2'){ //pessoa juridica
            obj.cpf = "";
            obj.code = "";
        }
        if(obj.compType == '3'){ //pessoa fisica internaciol
            obj.cpf = "";
            obj.cnpj = "";
        }
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function updatePartners() {
            try {
                const { data } = await api.post('/Affiliate/UpdateAffiliateAsync',
                    obj
                    , config
                );
                if (data.status !== 400) {
                    setModalResponse("success");
                    setModalText(data.data.texto);
                    setAllowRedirect(true);
                    setLog(data.data.log)
                }
            } catch (error: any) {
                setModalResponse("error");
                setModalText(error?.response?.data?.errorMessage || "Erro ao processar operação");
            }
        }
        updatePartners();
    }

    return (
        <>
            {personalDataEdit && financialEdit && documents && bankDataEdit && usersEdit
                ?
                <>
                    <EditPersonalData info={info} setChangeEdit={setPersonalDataEdit} setShowLog={setShowLog}/>
                    <EditDocuments info={info} setChangeEdit={setDocuments} showDocument={showDocument} />
                    <EditFinancial info={info} setChangeEdit={setFinancialEdit} />
                    <EditBankData info={info} setChangeEdit={setBankDataEdit} showDocument={showDocument} />
                    <EditUsers info={info} setChangeEdit={setUsersEdit} usersEdit={true}/>
                </>
                : <></>
            }

            {!personalDataEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionPersonal action={handleNext} ContextAwareToggle={ContextAwareToggle} infoEdit={info} isEdit={true} editPartners={editPartners} />
                </Accordion>
                : <></>
            }

            {!documents
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionDocuments action={handleNext} ContextAwareToggle={ContextAwareToggle} compType={info.compType.toString()} setStepCtrl={setStepCtrl} infoEdit={info} isEdit={true} editPartners={editPartners} />
                </Accordion>
                : <></>
            }

            {!financialEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionFinancial action={handleNext} ContextAwareToggle={ContextAwareToggle} setStepCtrl={setStepCtrl} infoEdit={info} isEdit={true} editPartners={editPartners} />
                </Accordion>
                : <></>
            }


            {!bankDataEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionAccount action={handleNext} ContextAwareToggle={ContextAwareToggle} setStepCtrl={setStepCtrl} infoEdit={info} isEdit={true} editPartners={editPartners} />
                </Accordion>
                : <></>
            }

            {!usersEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionUsers action={handleNext} ContextAwareToggle={ContextAwareToggle} setStepCtrl={setStepCtrl} infoEdit={info} isEdit={true} editPartners={editPartners} />
                </Accordion>
                : <></>
            }

            <Modal
                className="modal-custom reservation-details"
                show={showModalDocument}
                onHide={() => { setDocument(null); setShowModalDocument(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <div className="d-flex justify-content-center h-100 flex-column" >
                        {/* <h4 className='text-center my-3'>{documentName}</h4> */}
                        <div className=""
                            style={{ backgroundImage: `url(${document})`, width: "100%", height: "100%", backgroundSize: 'contain', backgroundPosition: "center center", backgroundRepeat: 'no-repeat' }}>
                        </div>
                    </div>
                </>
            </Modal>

            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => {
                            if(allowRedirect){
                                window.location.reload();
                            }
                            setModalResponse(null)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton></Modal.Header>
                            
                            <RequestResponseModal log={log} modalResponse={modalResponse} modalText={modalText} setModalResponse={setModalResponse} redirectOnSuccess={`/partners/`}/>
                        </>
                    </Modal>
                </div>
            </Row>

            <>
                <Modal
                    className="modal-custom modalAuth modal-log"
                    show={showLog !== null}
                    onHide={() => {
                        if (allowRedirect) {
                            window.location.reload();
                        }
                        setShowLog(null)
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Header closeButton>
                            <h3>Histórico de alterações</h3>
                        </Modal.Header>

                        <hr className='m-0 mb-4' />

                        <Modal.Body>
                            <EditPartnersLog setShowLog={setShowLog} info={info} />
                        </Modal.Body>
                    </>
                </Modal>
            </>

        </>
    );
}

export default EditDataFields;