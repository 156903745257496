import React, { useState } from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import { useTranslation } from "react-i18next";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";


export interface propFilter {
    buscar: any,
    clearActualValues: any;
    allowLoadFromFilter?: any;
}

const FilterCognitivo: React.FC<propFilter> = ({
    buscar, clearActualValues, allowLoadFromFilter
}) => {

    const { t } = useTranslation();

    
    const [filterBeginDate, setFilterBeginDate] = useState<any>([]);   

    const [allowOperation, setAllowOperation] = useState<any>(false);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */


    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterBeginDate.length === 2) {
            let beginDate = filterBeginDate[0].split('/')
            let endDate = filterBeginDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

            dataIntervalLong = (days > 90); // 3 meses
            
        }

        if(dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar( filterBeginDate);
    };

    const incDate = (e: any, dias: number) => {
        e.preventDefault();

        const today = new Date();

        const newDate = (filterBeginDate.length === 0) ? `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}` : filterBeginDate[0];

        const datao = newDate.split('/');
        
        const newDate1 = new Date(parseInt(datao[2]), parseInt(datao[1])-1, parseInt(datao[0])+dias);
        //console.log(parseInt(datao[0])+dias); return;
        const newDateStr = `${newDate1.getDate()}/${newDate1.getMonth()+1}/${newDate1.getFullYear()}`

        setFilterBeginDate([newDateStr]);

        buscar([newDateStr]);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        
        setFilterBeginDate([]);
        buscar("", [], null, null, null);
        clearActualValues();
    };
    

   

return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Sessões</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">                                           
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Data da session</Form.Label>
                                                <div className="d-flex">                                                   
                                                    <InputGroup hasValidation className="ranger-calendar d-flex">                                                    
                                                        <RangeCalendar date={filterBeginDate} setDate={setFilterBeginDate} />                                                        
                                                    </InputGroup>                                                    
                                                </div>
                                            </Form.Group>    
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterCognitivo;